<template>
  <div>
    <new-campaign-kpi-sidebar />
    <template v-if="loading">
      <div class="d-flex justify-content-center m-2">
        <b-spinner variant="primary" />
      </div>
    </template>
    <template v-else>
      <div v-if="!dnaSelected">
        <div style="position: relative">
          <app-breadcrumb :breadcrumbTitle="false">
            <b-button
              v-if="canCreateCampaign"
              variant="primary"
              @click="createCampaign"
              style="position: absolute; right: 13"
            >
              <feather-icon icon="PlusCircleIcon" class="mr-50" />
              <span class="text-nowrap">Nova campanha</span>
            </b-button>
          </app-breadcrumb>
        </div>
        <b-row>
          <div class="card card-campaign mx-2">
            <div class="card-body">
              <b-row class="align-items-center">
                <b-col cols="6">
                  <h5>DNA Campeão 🎉</h5>
                  <b-card-text class="mt-1 mb-0">
                    Meu progresso neste mês
                  </b-card-text>
                  <b-progress
                    :value="dnaProgress"
                    max="100"
                    variant="success"
                    height="20px"
                    class="mt-1"
                  />
                  <button
                    type="button"
                    class="btn btn-primary waves-effect waves-float waves-light mt-2"
                    @click="searchDna"
                  >
                    Ver detalhes
                  </button>
                </b-col>
                <b-col cols="auto">
                  <img
                    src="~@/assets/images/dna_campeao.png"
                    alt="DNA Campeão"
                    height="150"
                    width="190"
                  />
                </b-col>
              </b-row>
            </div>
          </div>
          <div
            class="card card-campaign mx-2"
            v-for="(campaign, index) in campaigns"
            :key="index"
          >
            <div class="card-body">
              <b-row class="align-items-center">
                <b-col cols="7">
                  <h5>{{ campaign.name }}</h5>
                  <p style="margin: 15px 0 45px 0">
                    {{ formatDate(campaign.start_date) }} à
                    {{ formatDate(campaign.end_date) }}
                  </p>
                  <button
                    type="button"
                    class="btn btn-primary waves-effect waves-float waves-light"
                    @click="openCampaing(campaign)"
                  >
                    Ver detalhes
                  </button>
                  <b-button
                    :id="`edit-campaign-${campaign.id}`"
                    v-if="canUpdateCampaign"
                    variant="outline-primary"
                    @click="editCampaign(campaign)"
                    style="font-size: 8px; padding: 10px; margin-left: 8px"
                  >
                    <FeatherIcon icon="Edit2Icon" size="16" />
                  </b-button>
                  <b-button
                    :id="`delete-campaign-${campaign.id}`"
                    v-if="canDeleteCampaign"
                    variant="outline-primary"
                    @click="deleteCampaign(campaign.id)"
                    style="font-size: 8px; padding: 10px; margin-left: 8px"
                  >
                    <FeatherIcon icon="Trash2Icon" size="16" />
                  </b-button>
                </b-col>
                <b-col cols="auto">
                  <img
                    :src="campaign.image"
                    :alt="campaign.name"
                    height="150"
                    width="150"
                  />
                </b-col>
              </b-row>
            </div>
          </div>
        </b-row>
      </div>
      <div v-else>
        <b-button
          variant="outline-primary"
          @click="dnaSelected = false"
          class="mb-2"
        >
          <feather-icon icon="ArrowLeftIcon" size="14" />
          Voltar
        </b-button>
        <b-row>
          <b-col cols="12" md="3">
            <b-card class="dna-category-card">
              <b-card-title class="text-center mb-0">
                <span>Aprendiz</span>
                <b-card-text class="category-card-help">
                  <b-button
                    variant="flat"
                    class="btn-icon"
                    @click="openCategoryDetails('aprendiz')"
                  >
                    <feather-icon
                      icon="InfoIcon"
                      size="25"
                      class="text-primary cursor-pointer"
                    />
                  </b-button>
                </b-card-text>
              </b-card-title>
              <b-row class="justify-content-center px-2">
                <b-card-text class="mt-1"> Meu progresso total: </b-card-text>
                <b-progress
                  :value="apprenticeTotalProgress"
                  max="100"
                  variant="success"
                  height="20px"
                  class="col-12 p-0"
                />
                <span class="badge badge-primary badge-pill mt-2"
                  >{{ apprenticeTotal }} de {{ apprenticeMax }} pontos</span
                >
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="12" md="3">
            <b-card class="dna-category-card">
              <b-card-title class="text-center mb-0">
                <span>Focado</span>
                <b-card-text class="category-card-help">
                  <b-button
                    variant="flat"
                    class="btn-icon"
                    @click="openCategoryDetails('focado')"
                  >
                    <feather-icon
                      icon="InfoIcon"
                      size="25"
                      class="text-primary cursor-pointer"
                    />
                  </b-button>
                </b-card-text>
              </b-card-title>
              <b-row class="justify-content-center px-2">
                <b-card-text class="mt-1"> Meu progresso total: </b-card-text>
                <b-progress
                  :value="focusedProgress"
                  max="100"
                  variant="success"
                  height="20px"
                  class="col-12 p-0"
                />
                <span class="badge badge-primary badge-pill mt-2"
                  >{{ focusedTotal }} de {{ focusedMax }} pontos</span
                >
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="12" md="3">
            <b-card class="dna-category-card">
              <b-card-title class="text-center mb-0">
                <span>Protagonista</span>
                <b-card-text class="category-card-help">
                  <b-button
                    variant="flat"
                    class="btn-icon"
                    @click="openCategoryDetails('protagonista')"
                  >
                    <feather-icon
                      icon="InfoIcon"
                      size="25"
                      class="text-primary cursor-pointer"
                    />
                  </b-button>
                </b-card-text>
              </b-card-title>
              <b-row class="justify-content-center px-2">
                <b-card-text class="mt-1"> Meu progresso total: </b-card-text>
                <b-progress
                  :value="protagonistProgress"
                  max="100"
                  variant="success"
                  height="20px"
                  class="col-12 p-0"
                />
                <span class="badge badge-primary badge-pill mt-2"
                  >{{ protagonistTotal }} de {{ protagonistMax }} pontos</span
                >
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="12" md="3">
            <b-card class="dna-category-card">
              <b-card-title class="text-center mb-0">
                <span>Campeão</span>
                <b-card-text class="category-card-help">
                  <b-button
                    variant="flat"
                    class="btn-icon"
                    @click="openCategoryDetails('campeão')"
                  >
                    <feather-icon
                      icon="InfoIcon"
                      size="25"
                      class="text-primary cursor-pointer"
                    />
                  </b-button>
                </b-card-text>
              </b-card-title>
              <b-row class="justify-content-center px-2">
                <b-card-text class="mt-1"> Meu progresso total: </b-card-text>
                <b-progress
                  :value="championProgress"
                  max="100"
                  variant="success"
                  height="20px"
                  class="col-12 p-0"
                />
                <span class="badge badge-primary badge-pill mt-2"
                  >{{ championTotal }} de {{ championMax }} pontos</span
                >
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </div>
      <!-- Card maior com informações do DNA -->
      <b-card v-if="dnaSelected">
        <b-row class="justify-content-end">
          <b-col cols="auto">
            <b-button variant="primary" @click="openRegulaments">
              <feather-icon icon="ExternalLinkIcon" size="14" />
              Ver regulamento
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="6"
            v-for="(level, index) in dnaInfo.levels"
            :key="index"
            class="mb-3"
          >
            <h3 class="mb-1">{{ level.name }}</h3>
            <p class="mb-0">
              Limite de pontos:
              <strong
                v-if="level.points.range && Array.isArray(level.points.range)"
              >
                {{ level.points.range[0] }} - {{ level.points.range[1] }}
              </strong>
              <strong v-else-if="level.points.total">
                {{ level.points.total }}
              </strong>
            </p>
            <p class="mb-2">
              Frequência: <strong>{{ level.points.frequency }}</strong>
            </p>
            <ul class="list-group">
              <li
                v-for="(criterion, index) in level.criteria"
                :key="index"
                class="list-group-item d-flex justify-content-between align-items-center"
              >
                <span>{{ criterion.description }}</span>
                <span class="badge badge-secondary badge-pill">{{
                  criterion.points
                }}</span>
              </li>
            </ul>
          </b-col>
        </b-row>
      </b-card>
    </template>
  </div>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import * as types from "../store/types";
import * as accountTypes from "@/modules/account/store/types";
import useAppConfig from "@core/app-config/useAppConfig";
import dnaHunterClosersInfo from "@/json/info-pns-hunter-closes-franqueado.json";
import dnaFePlusInfo from "@/json/info-pns-fe-plus.json";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import AppBreadcrumb from "@core/layouts/components/AppBreadcrumb.vue";
import NewCampaignKpiSidebar from "@/modules/kpi/components/NewCampaignKpiSidebar.vue";
import {
  CAMPAIGN_KPI,
  CREATE_ACTION,
  UPDATE_ACTION,
  DELETE_ACTION,
} from "@/constants/resources";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";

export default {
  components: { AppBreadcrumb, NewCampaignKpiSidebar },
  data() {
    return {
      dnaCampaingRegulation: process.env.VUE_APP_DNA_CAMPAING_REGULATIONS,
      selectedCampaign: null,
      loading: false,
      dnaProgress: 0,
      dnaSelected: false,
      dnaData: undefined,
      apprenticeTotal: 0,
      focusedTotal: 0,
      protagonistTotal: 0,
      championTotal: 0,
      apprenticeMax: 1,
      focusedMax: 1,
      protagonistMax: 1,
      championMax: 1,
    };
  },
  computed: {
    ...mapGetters({
      user: accountTypes.USER,
      isPns: accountTypes.IS_PNS,
      campaigns: types.CAMPAIGNS_KPI,
    }),
    dnaInfo() {
      return this.isPns ? dnaHunterClosersInfo : dnaFePlusInfo;
    },
    apprenticeProgress() {
      const percentage = (this.apprenticeTotal / this.apprenticeMax) * 100;
      return percentage > 100 ? 100 : percentage;
    },
    focusedProgress() {
      const percentage = (this.focusedTotal / this.focusedMax) * 100;
      return percentage > 100 ? 100 : percentage;
    },
    protagonistProgress() {
      const percentage = (this.protagonistTotal / this.protagonistMax) * 100;
      return percentage > 100 ? 100 : percentage;
    },
    championProgress() {
      const percentage = (this.championTotal / this.championMax) * 100;
      return percentage > 100 ? 100 : percentage;
    },
    canCreateCampaign: function () {
      return this.$can(CREATE_ACTION, CAMPAIGN_KPI);
    },
    canUpdateCampaign: function () {
      return this.$can(UPDATE_ACTION, CAMPAIGN_KPI);
    },
    canDeleteCampaign: function () {
      return this.$can(DELETE_ACTION, CAMPAIGN_KPI);
    },
  },
  setup() {
    const { skin } = useAppConfig();

    return { toast: useToast(), skin };
  },
  mounted() {
    this.loading = true;
    this.fetchCampaigns();
    this.getDnaProgress()
      .then((resp) => {
        const progress = resp.data;
        this.dnaProgress = progress > 100 ? 100 : progress;
      })
      .catch((err) => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os dados da campanha. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    ...mapActions({
      getDnaCampaing: types.GET_DNA_CAMPAING,
      getDnaProgress: types.GET_DNA_PROGRESS,
      getCampaignsKpi: types.GET_CAMPAIGNS_KPI,
      openNewCampaignKpiSidebar: types.OPEN_NEW_CAMPAIGN_KPI_SIDEBAR,
      deleteCampaignKpi: types.DELETE_CAMPAIGN_KPI,
    }),
    formatDate(date) {
      if (!date) return "";
      const [year, month, day] = date.split("T")[0].split("-");
      return `${day}/${month}/${year}`;
    },
    async fetchCampaigns() {
      this.loading = true;
      this.getCampaignsKpi()
        .catch((err) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar os dados da campanha. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    searchDna() {
      this.loading = true;

      return this.getDnaCampaing()
        .then((resp) => {
          this.dnaData = resp.data.dnaData;
          this.dnaSelected = true;
          this.apprenticeTotal =
            _(this.dnaData).filter({ pillar: "Aprendiz" }).sumBy("score") || 0;
          this.focusedTotal =
            _(this.dnaData).filter({ pillar: "Focado" }).sumBy("score") || 0;
          this.protagonistTotal =
            _(this.dnaData).filter({ pillar: "Protagonista" }).sumBy("score") ||
            0;
          this.championTotal =
            _(this.dnaData).filter({ pillar: "Campeao" }).sumBy("score") || 0;
          this.apprenticeMax = resp.data.apprenticeMax;
          this.focusedMax = resp.data.focusedMax;
          this.protagonistMax = resp.data.protagonistMax;
          this.championMax = resp.data.championMax;
        })
        .catch((err) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar os dados da campanha. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    createCampaign() {
      this.openNewCampaignKpiSidebar({
        id: undefined,
        saveAction: () => {
          this.fetchCampaigns();
        },
      });
    },
    editCampaign(campaign) {
      this.openNewCampaignKpiSidebar({
        id: campaign.id,
        saveAction: () => {
          this.fetchCampaigns();
        },
        visible: true,
      });
    },
    deleteCampaign(idCampaign) {
      this.$swal({
        title: "Tem certeza?",
        html: `
          <div style="text-align: center;">
            Essa ação não poderá ser desfeita!
          </div>
        `,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
        buttonsStyling: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        width: "40vw",
        heightAuto: false,
        background: `${this.skin === "dark" ? "#283046" : ""}`,
        customClass: {
          cancelButton: "btn btn-secondary mr-1",
          popup: "modal-height",
          confirmButton: "btn btn-primary",
          title: "text-primary",
          htmlContainer: `info-responsive ${
            this.skin === "dark" ? "text-white" : ""
          }`,
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteCampaignKpi(idCampaign)
            .then((response) => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Sucesso",
                  text: "A campanha foi removida com sucesso!",
                  icon: "CoffeeIcon",
                  variant: "success",
                },
              });

              this.fetchCampaigns();
            })
            .catch((error) => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Oops!",
                  text: "Ocorreu um erro ao criar a campanha. Entre em contato com o setor de TI.",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
              console.error(error);
            });
        }
      });
    },
    openCampaing(campaign) {
      this.$swal({
        backdrop: true,
        title: `${campaign.name}`,
        html: `
          <div style="text-align: center;">
            <img
              src="${campaign.image}"
              alt="Banner da Campanha"
              style="width: 100%; max-height: 200px; object-fit: contain; margin-bottom: 20px;"
            />
            <p>${campaign.details}</p>
             <a
              href="${campaign.link}"
              target="_blank"
              style="
                display: inline-flex;
                align-items: center;
                color: #f26237;
                padding: 10px 15px;
                text-decoration: none;
                font-size: 14px;
                font-weight: 600;
              ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
                style="margin-right: 5px;">
                <path d="M6.354 5.5H2.5a.5.5 0 0 1 0-1h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V6.207l-5.646 5.647a.5.5 0 0 1-.708-.708L6.354 5.5zm6.5-4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V2.707L7.207 8.854a.5.5 0 0 1-.708-.708L11.293 2H8.5a.5.5 0 0 1 0-1h4z"/>
              </svg>
              Ver regulamento
            </a>
          </div>
        `,
        confirmButtonText: "Ok",
        allowOutsideClick: false,
        allowEscapeKey: false,
        width: "40vw",
        heightAuto: false,
        background: `${this.skin === "dark" ? "#283046" : ""}`,
        customClass: {
          popup: "modal-height",
          confirmButton: "btn btn-primary",
          title: "text-primary",
          htmlContainer: `info-responsive ${
            this.skin === "dark" ? "text-white" : ""
          }`,
        },
      });
    },
    openRegulaments() {
      setTimeout(() => {
        window.open(this.dnaCampaingRegulation, "_blank");
      });
    },
    openCategoryDetails(category) {
      let body = "";
      const details = _.filter(
        this.dnaData,
        (l) => l.pillar.toLowerCase() == category
      );
      if (details.length > 0) {
        body += "<br><br><h3>Detalhes da pontuação:</h3>";
        body += `<ul>`;
        details.forEach((detail) => {
          body += `<li>${detail.explanation}: <b>${detail.score} pontos</b></li>`;
        });
        body += `</ul>`;
      } else {
        body += `<br><br><h4>Não foram encontrados pontos para essa categoria.</h4>`;
      }

      this.$swal({
        backdrop: true,
        title: `Detalhamento da categoria ${category}`,
        html: body,
        confirmButtonText: "Ok",
        allowOutsideClick: false,
        allowEscapeKey: false,
        width: "40vw",
        heightAuto: false,
        background: `${this.skin === "dark" ? "#283046" : ""}`,
        customClass: {
          popup: "modal-height",
          confirmButton: "btn btn-primary",
          title: "text-primary",
          htmlContainer: `info-responsive ${
            this.skin === "dark" ? "text-white" : ""
          }`,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.card-campaign {
  min-width: 450px;
}

.dna-category-card {
  display: flex;
}

.details-container {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.detail-card {
  width: 45%;
}

.info-card {
  width: 100%;
  margin-top: 20px;
}

.open-campaign {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  font-weight: 100;

  button {
    display: flex;
    height: 28px;
    font-size: 12px;
    align-items: center;
  }
}

.category-card-help {
  position: absolute;
  top: 9px;
  right: 10px;
}

.max-progress {
  margin-left: 10px;
  font-weight: 600;
}
</style>
